'use client';
import React, { useMemo } from 'react';

import { format } from 'date-fns';
import {
  Chevron as DayPickerChevron,
  CaptionLabelProps,
  DayPicker as DatePickerComponent,
  DayButtonProps,
  DayPickerProps,
  PropsMulti,
  PropsSingle,
} from 'react-day-picker';

import { ArrowIcon } from '@bloom/library/components/Icon/Arrow';

import style from './DatePicker.module.css';

const Chevron: React.FC<React.ComponentProps<typeof DayPickerChevron>> = ({ orientation }) => (
  <ArrowIcon rotate={orientation === 'left' ? 180 : 0} width={16} />
);

const CaptionLabel: React.FC<CaptionLabelProps> = (props) => {
  return <span {...props} data-testid="date-picker-caption-label" />;
};

const DayButton: React.FC<DayButtonProps> = (props) => {
  const { children, day, modifiers, ...restProps } = props;

  if (modifiers.hidden) {
    return null;
  }

  return (
    <button {...restProps} title={format(day.date, 'yyyy-MM-dd')} type="button">
      <span
        className={`${style.dayContent} inline-flex h-full w-full items-center justify-center rounded-full`}
      >
        {children}
      </span>
    </button>
  );
};

const DatePickerSingle: React.FC<PropsSingle> = (props) => {
  const { onSelect, selected, ...restProps } = props;

  function handleDateSelect(date?: Date) {
    if (typeof onSelect === 'function') {
      onSelect(date ? format(date, 'yyyy-MM-dd') : '');
    }
  }

  return (
    <DatePickerComponent
      {...restProps}
      mode="single"
      onSelect={handleDateSelect}
      selected={selected}
    />
  );
};

const DatePicker: React.FC<React.PropsWithChildren<DayPickerProps>> = (props) => {
  const { children, classNames, components, formatters, ...restProps } = props;
  const weekdayName = useMemo(() => ['S', 'M', 'T', 'W', 'T', 'F', 'S'], []);

  const defaultMonth = useMemo(
    () => {
      const today = new Date();
      if (props.mode === 'multiple') {
        return props.selected?.[0] || today;
      }

      if (props.mode === 'range') {
        return props.selected?.from || today;
      }

      return ('selected' in props ? props.selected : undefined) || today;
    },
    // Do not add selected in the dependency array.
    // The defaultMonth should be set only once on mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const commonProps = useMemo(() => {
    return {
      'data-testid': 'date-picker',
      defaultMonth,
      showOutsideDays: true,
    };
  }, [defaultMonth]);

  const notOverriddenProps = useMemo(
    () => ({
      classNames: {
        button_next:
          'inline-flex p-2 w-[calc(100%/7)] justify-center disabled:cursor-not-allowed disabled:text-black-50 disabled:dark:text-white-50',
        button_previous:
          'inline-flex p-2 w-[calc(100%/7)] justify-center disabled:cursor-not-allowed disabled:text-black-50 disabled:dark:text-white-50',
        caption_label:
          'absolute left-1/2 -top-1 -translate-x-1/2 -translate-y-full whitespace-nowrap text-lg font-medium',
        day: `${style.day} px-0`,
        day_button: `${style.day_button} flex h-9 w-9 cursor-pointer items-center justify-center rounded-full font-medium select-none mx-auto`,
        disabled: `${style.disabled} text-black-50 dark:text-white-50 !cursor-not-allowed`,
        month_caption: 'relative',
        month_grid: 'table-fixed border-collapse',
        months: 'inline-flex text-black dark:text-white flex-col',
        nav: 'flex items-center justify-between',
        range_end: style.range_end,
        range_middle: style.range_middle,
        range_start: style.range_start,
        selected: style.selected,
        today: 'text-brand',
        weekday: 'font-regular text-black-50 dark:text-white-50 py-2',
        ...classNames,
      },
      components: {
        CaptionLabel,
        Chevron,
        DayButton,
        ...components,
      } as DayPickerProps['components'],
      formatters: { formatWeekdayName: (date: Date) => weekdayName[date.getDay()]!, ...formatters },
    }),
    [classNames, components, formatters, weekdayName]
  );

  return (
    <>
      {props.mode === 'single' || props.mode === undefined ? (
        <DatePickerSingle
          {...commonProps}
          {...(restProps as PropsSingle)}
          {...notOverriddenProps}
          mode="single"
        />
      ) : null}

      {props.mode === 'multiple' ? (
        <DatePickerComponent
          {...commonProps}
          {...(restProps as PropsMulti)}
          {...notOverriddenProps}
        />
      ) : null}

      {children}
    </>
  );
};

export { DatePicker };
